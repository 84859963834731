.item {
    display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 0px;
	border-bottom: 1pt solid #F0f0f0;
	.time {
		font-size: 12px;color: #999;
	}
	.date {
		font-size: 12px;color: #999;
	}
	.actions {
		font-size: 13px;
		color: #C00;
	}
	.button {
		font-size: 13px;
		color:#333;
		padding:4px 8px;
		border:1pt solid #d9d9d9;
		border-radius: 2px;
		box-shadow: 0 2px #00000004;
	}
	.button:hover {
		color:#40a9ff;
		border-color:#40a9ff
	}
}
