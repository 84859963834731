@import 'normalize.css';
@import '~antd/dist/antd.css';

:root {
    --primary: #1890ff;
    --secondary: #eee;
    --black: #000;
    --white: #fff;
    --blue1: rgb(35, 100, 240);
    --text-color: #161823;

    --font-family: 'Inter', sans-serif;

    // Default layout
    --default-layout-header-height: 60px;
    --default-layout-width: 1150px;
    --default-layout-horizontal-spacer: 24px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: var(--font-family);
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizespeed;
    color: var(--text-color);
    overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a.link {
    color: var(--text-color);
    text-decoration: none;
}
.text-black {
    color: var(--black);
}
ul,
ol {
    margin-left: 20px;
}
ul:last-child,
ol:last-child {
    margin-bottom: 0px;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700;800;900&display=swap');

// Custom tippy tooltip
body {
    .tippy-box {
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
        background-color: rgba(84, 84, 84, 0.92);
    }

    .tippy-arrow {
        color: rgba(84, 84, 84, 0.92);
    }

    .tippy-content {
        padding: 8px 9px;
    }

    .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
        top: -8px;
    }
    .dpn {
        display: none;
    }
    .p0 {
        padding: 0px;
    }
}
.text-right {
    text-align: right;
}
.pt-3 {
    padding-top: 3px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-12 {
    margin-bottom: 12px;
}
.mb-24 {
    margin-bottom: 24px;
}
.text-center {
    text-align: center;
}
.mw600 {
    max-width: 600px;
}
.m-auto {
    margin: 0 auto;
}
.p-0 {
    padding: 0px;
}
.plr0 {
    padding-left: 0px;
    padding-right: 0px;
}
.w-100 {
    width: 100%;
}
.box-flex-center {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.card {
    border: 1pt solid rgba(22, 24, 35, 0.03);
    box-shadow: 0px 0px 2px 2px rgba(22, 24, 35, 0.03);
    padding: 16px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    & .card-footer {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }
}
.form-group {
    width: 100%;
    & .form-element {
        margin-bottom: 12px;
    }
    & label:first-child {
        display: block;
        width: 100%;
        font-weight: 600;
    }
    & .error {
        margin-bottom: 10px;
        font-size: 14px;
        color: #fe2c55;
    }
    & .form-element {
        & input,
        textarea {
            border: 1pt solid #ccc;
            line-height: 36px;
            border-radius: 4px;
            padding-left: 8px;
            outline: 0;
            width: 100%;

            &:focus {
                border: 1pt solid var(--blue1);
            }
        }
        & input[type='radio'] {
            width: auto;
            margin-right: 4px;
        }
        & input[type='checkbox'] {
            width: auto;
            margin-right: 4px;
        }
    }
}
.rounder {
    border: 1px solid #f0f0f0;
    padding: 16px;
}
.bold {
    font-weight: 600;
}
.error {
    font-size: 14px;
    color: #fe2c55;
}
.d-flex {
    display: flex;
}
.space-between {
    justify-content: space-between;
}
.w40per {
    width: 40%;
}
.w49per {
    width: 49%;
}
.w50per {
    width: 50%;
}
.col3items {
    width: 32%;
}
.btn_only_icon {
    padding: 4px 6px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 8px;
}
.rowEditInline {
    position: relative;
    & .BtnEdit {
        position: absolute;
        right: 5px;
        top: 5px;
        display: none;
    }
    & .itemtext {
        line-height: 36px;
    }
}
.rowEditInline:hover {
    & .BtnEdit {
        display: block;
    }
}
.listitems {
    padding-left: 16px;
    & a {
        color: #25799f;
    }
    & a:hover {
        text-decoration: underline;
    }
}

.descriptionbox {
    & ul {
        margin-left: 20px;
    }
}
//ant css
.ant-select-item-option-content img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.ant-select-selector {
    width: 100%;
}
.listusers .ant-select-selection-search-input {
    padding-left: 28px !important;
}
.ant-select-item-option-content .ant-avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.ant-layout-sider-children {
    border-right: 1pt solid #eee;
    height: calc(100vh - 60px);
    background-color: #f5f5f5;
}
.ant-layout-sider {
    background: none;
}
.ant-layout-sider-trigger {
    // display:none
}
.site-layout-background {
    background: #fff;
}
.ant-tag-Review {
    color: #096dd9;
    background: #e6f7ff;
    border-color: #91d5ff;
}
.ant-tag-Done {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
}
.ant-tag-Doing {
    color: #d4380d;
    background: #fff2e8;
    border-color: #ffbb96;
}
.no-label {
    margin: -16px;
    & label {
        display: none !important;
    }
    & .ant-form-item {
        margin-bottom: 0px !important;
    }
    & .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 1pt solid #eee;
    }
    & .ql-container.ql-snow {
        border: none;
    }
}

#res {
    white-space: pre;
}
.ant-collapse-header-text {
    font-weight: 600;
}
.ant-collapse > .ant-collapse-item,
.ant-collapse,
.ant-collapse-content {
    border-color: #f0f0f0 !important;
}
.mycmtlist .ant-spin-container {
    max-height: 400px;
    overflow: scroll;
}
.listpartnerindex {
    & .ant-card-cover {
        overflow: hidden;
        height: 130px;
    }
    & .ant-card-meta-title {
        font-size: 20px;
        font-weight: 600;
    }
}
.detaillisttodo {
    & .ant-typography-edit {
        display: none !important;
    }
    & .ant-typography {
        cursor: pointer;
        line-height: 34px;
    }
    & .ant-typography:hover .ant-typography-edit {
        display: inline-block !important;
    }
}

//reactQuill
.ql-container {
    min-height: 15rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
}
.headermobile {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100%;
    background: linear-gradient(102deg, rgb(112, 104, 222) 0%, rgb(216, 90, 185) 100%);
    z-index: 999;
	top:0px;
	color:#fff
}
.mainlayout {
    overflow: auto;
    height: calc(100vh - 60px);
}
.react-kanban-board {
    display: block !important;
}
.react-kanban-column {
    width: calc((100% - 50px) / 5);
    div > div {
        display: block !important;
    }
}
.ant-table-container p {
    margin-bottom: 0px;
}
.sticky {
	position: sticky;
	z-index: 99;
	top:0
}
.action_footer {
	position: absolute;
	display: flex;
	bottom: 0;
	width: 100%;
	background: linear-gradient(102deg, rgb(112, 104, 222) 0%, rgb(216, 90, 185) 100%);
	justify-content: center;
	align-items: center;
	height: 70px;
}
.menufooter_mobile {
	position: relative;
	width: 100%;
	height: 70px;
	background: linear-gradient(102deg, rgb(112, 104, 222) 0%, rgb(216, 90, 185) 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
}
.menufooter_mobile ul {
	display: flex;
	margin:0px
}
.menufooter_mobile ul li {
	position: relative;
	list-style: none;
	width: 70px;
	height: 70px;
	z-index: 1;
}
.menufooter_mobile ul li a {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-weight: 500;
	text-align: center;
	width: 100%;
	top:24px
}
.menufooter_mobile ul li a .icon {
	position: relative;
	display: block;
	line-height: 75px;
	font-size: 24px;
	text-align: center;
	transition: 0.5s;
	color:#fff;
}
.menufooter_mobile ul li.active a .icon {
	transform: translateY(-36px);
}
.menufooter_mobile ul li a .text {
	position: absolute;
	color: #222327;
	font-weight: 400;
	font-size: 0.75em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	opacity: 0;
	transform: translateY(20px);
	width:70px;
}
.menufooter_mobile ul li.active .text {
	opacity: 1;
	transform: translateY(10px);
}
.indicator {
	position: absolute;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	top:-50%;
	background: #40a9ff;
	border: 6pt solid #fff;
	transition: 0.5s;
}
.indicator::before {
	content: '';
	position: absolute;
	top:50%;
	left: -22px;
	width: 20px;
	height: 20px;
	background: transparent;
	border-top-right-radius: 20px;
	box-shadow: 1px -10px 0 0 #fff;
}
.indicator::after {
	content: '';
	position: absolute;
	top:50%;
	right: -22px;
	width: 20px;
	height: 20px;
	background: transparent;
	border-top-left-radius: 20px;
	box-shadow: -1px -10px 0 0 #fff;
}
.menufooter_mobile ul li:nth-child(1).active ~.indicator {
	transform: translateX(calc(70px*0));
}
.menufooter_mobile ul li:nth-child(2).active ~.indicator {
	transform: translateX(calc(70px*1));
}
.menufooter_mobile ul li:nth-child(3).active ~.indicator {
	transform: translateX(calc(70px*2));
}
.menufooter_mobile ul li:nth-child(4).active ~.indicator {
	transform: translateX(calc(70px*3));
}
.menufooter_mobile ul li:nth-child(5).active ~.indicator {
	transform: translateX(calc(70px*4));
}
.header-subtitle {
	background-color: #eee;padding:8px 16px; text-align: center; font-size: 14px;
}