.search,
.search-result {
    width: 361px;
}

.search {
    position: relative;
    height: var(--search-height);
    padding-left: 16px;
    display: flex;
    background-color: rgba(22, 24, 35, 0.06);
    border-radius: var(--search-border-radius);
    border: 1.5px solid transparent;

    input {
        flex: 1;
        height: 100%;
        padding-right: 40px;
        color: var(--black);
        font-size: 1.4rem;
        caret-color: var(--primary);
        background-color: transparent;
        font-family: var(--font-family);
    }

    input:not(:placeholder-shown) ~ .search-btn {
        color: rgba(22, 24, 35, 0.75);
    }

    &::after {
        content: '';
        position: absolute;
        top: var(--search-top-spacer);
        right: var(--search-button-width);
        width: 1px;
        height: calc(var(--search-height) - var(--search-top-spacer) * 2);
        background-color: rgba(22, 24, 35, 0.12);
    }

    &:focus-within {
        border-color: rgba(22, 24, 35, 0.2);
    }
}

.search-title {
    padding: 5px 12px;
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
}

.clear,
.loading {
    position: absolute;
    right: calc(var(--search-button-width) + 16px);
    top: 50%;
    transform: translateY(-50%);
    color: rgba(22, 24, 35, 0.34);
}

.loading {
    animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
    from {
        transform: translateY(-50%) rotate(0);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}

.search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--search-button-width);
    height: 100%;
    border-top-right-radius: var(--search-border-radius);
    border-bottom-right-radius: var(--search-border-radius);
    font-size: 1.8rem;
    color: rgba(22, 24, 35, 0.34);

    &:hover {
        cursor: pointer;
        background-color: rgba(22, 24, 35, 0.03);
    }

    &:active {
        background-color: rgba(22, 24, 35, 0.06);
    }
}
.projectlist >div:last-child {border-bottom: none;}