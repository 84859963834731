@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
/**
* get random number.
* @param {number} min - min number.
* @param {number} max - max number.
*/
/*
* variable
*/
html,
body {
  height: 100vh;
  width: 100vw;
}
body {
  font-family: 'Montserrat', sans-serif;
  background: #fff;
  position: relative;
  overflow: hidden;
  font-size: 100%;
}
.criterion {
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0;
  width: 0;
  transform: translate(-20px, -20px);
}
/*
* background
*/
.background {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 0;
  animation: background-animation 2s ease-in-out 4s 1 normal forwards;
}
.background0 {
  left: 0%;
  height: 100vh;
  background-color: #eb4747;
}
.background1 {
  left: 12.5%;
  height: 100vh;
  background-color: #eb7247;
}
.background2 {
  left: 25%;
  height: 100vh;
  background-color: #eb9d47;
}
.background3 {
  left: 37.5%;
  height: 100vh;
  background-color: #ebc747;
}
.background4 {
  left: 50%;
  height: 100vh;
  background-color: #e4eb47;
}
.background5 {
  left: 62.5%;
  height: 100vh;
  background-color: #b9eb47;
}
.background6 {
  left: 75%;
  height: 100vh;
  background-color: #8eeb47;
}
.background7 {
  left: 87.5%;
  height: 100vh;
  background-color: #64eb47;
}
.background8 {
  left: 100%;
  height: 100vh;
  background-color: #47eb56;
}
.background9 {
  left: 112.5%;
  height: 100vh;
  background-color: #47eb80;
}
.background10 {
  left: 125%;
  height: 100vh;
  background-color: #47ebab;
}
.background11 {
  left: 137.5%;
  height: 100vh;
  background-color: #47ebd5;
}
.background12 {
  left: 150%;
  height: 100vh;
  background-color: #47d5eb;
}
.background13 {
  left: 162.5%;
  height: 100vh;
  background-color: #47abeb;
}
.background14 {
  left: 175%;
  height: 100vh;
  background-color: #4780eb;
}
.background15 {
  left: 187.5%;
  height: 100vh;
  background-color: #4756eb;
}
.background16 {
  left: 200%;
  height: 100vh;
  background-color: #6447eb;
}
.background17 {
  left: 212.5%;
  height: 100vh;
  background-color: #8e47eb;
}
.background18 {
  left: 225%;
  height: 100vh;
  background-color: #b947eb;
}
.background19 {
  left: 237.5%;
  height: 100vh;
  background-color: #e447eb;
}
.background20 {
  left: 250%;
  height: 100vh;
  background-color: #eb47c7;
}
.background21 {
  left: 262.5%;
  height: 100vh;
  background-color: #eb479d;
}
.background22 {
  left: 275%;
  height: 100vh;
  background-color: #eb4772;
}
.background23 {
  left: 287.5%;
  height: 100vh;
  background-color: #eb4747;
}
/*
* text
*/
.text {
  position: absolute;
  width: 40px;
  line-height: 40px;
  opacity: 0;
  overflow: hidden;
}
.text::after {
  z-index: -1;
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 40px;
}
.text0 {
  left: -440px;
  top: 0;
  animation: text-animation0 1s ease-in-out 1s 1 normal forwards, text2-animation0 2s ease-in-out 5s 1 normal forwards;
}
.text0::after {
  animation: text-after-animation0 2s ease-in-out 3s 1 normal forwards;
}
.text1 {
  left: -400px;
  top: 0;
  animation: text-animation1 1s ease-in-out 1.2s 1 normal forwards, text2-animation1 2s ease-in-out 5s 1 normal forwards;
}
.text1::after {
  animation: text-after-animation1 2s ease-in-out 3s 1 normal forwards;
}
.text2 {
  left: -360px;
  top: 0;
  animation: text-animation2 1s ease-in-out 1.4s 1 normal forwards, text2-animation2 2s ease-in-out 5s 1 normal forwards;
}
.text2::after {
  animation: text-after-animation2 2s ease-in-out 3s 1 normal forwards;
}
.text3 {
  left: -320px;
  top: 0;
  animation: text-animation3 1s ease-in-out 1.6s 1 normal forwards, text2-animation3 2s ease-in-out 5s 1 normal forwards;
}
.text3::after {
  animation: text-after-animation3 2s ease-in-out 3s 1 normal forwards;
}
.text4 {
  left: -280px;
  top: 0;
  animation: text-animation4 1s ease-in-out 1.8s 1 normal forwards, text2-animation4 2s ease-in-out 5s 1 normal forwards;
}
.text4::after {
  animation: text-after-animation4 2s ease-in-out 3s 1 normal forwards;
}
.text5 {
  left: -240px;
  top: 0;
  animation: text-animation5 1s ease-in-out 2s 1 normal forwards, text2-animation5 2s ease-in-out 5s 1 normal forwards;
}
.text5::after {
  animation: text-after-animation5 2s ease-in-out 3s 1 normal forwards;
}
.text6 {
  left: -200px;
  top: 0;
  animation: text-animation6 1s ease-in-out 2.2s 1 normal forwards, text2-animation6 2s ease-in-out 5s 1 normal forwards;
}
.text6::after {
  animation: text-after-animation6 2s ease-in-out 3s 1 normal forwards;
}
.text7 {
  left: -160px;
  top: 0;
  animation: text-animation7 1s ease-in-out 2.4s 1 normal forwards, text2-animation7 2s ease-in-out 5s 1 normal forwards;
}
.text7::after {
  animation: text-after-animation7 2s ease-in-out 3s 1 normal forwards;
}
.text8 {
  left: -120px;
  top: 0;
  animation: text-animation8 1s ease-in-out 2.6s 1 normal forwards, text2-animation8 2s ease-in-out 5s 1 normal forwards;
}
.text8::after {
  animation: text-after-animation8 2s ease-in-out 3s 1 normal forwards;
}
.text9 {
  left: -80px;
  top: 0;
  animation: text-animation9 1s ease-in-out 2.8s 1 normal forwards, text2-animation9 2s ease-in-out 5s 1 normal forwards;
}
.text9::after {
  animation: text-after-animation9 2s ease-in-out 3s 1 normal forwards;
}
.text10 {
  left: -40px;
  top: 0;
  animation: text-animation10 1s ease-in-out 3s 1 normal forwards, text2-animation10 2s ease-in-out 5s 1 normal forwards;
}
.text10::after {
  animation: text-after-animation10 2s ease-in-out 3s 1 normal forwards;
}
.text11 {
  left: 0px;
  top: 0;
  animation: text-animation11 1s ease-in-out 3.2s 1 normal forwards, text2-animation11 2s ease-in-out 5s 1 normal forwards;
}
.text11::after {
  animation: text-after-animation11 2s ease-in-out 3s 1 normal forwards;
}
.text12 {
  left: 40px;
  top: 0;
  animation: text-animation12 1s ease-in-out 3.4s 1 normal forwards, text2-animation12 2s ease-in-out 5s 1 normal forwards;
}
.text12::after {
  animation: text-after-animation12 2s ease-in-out 3s 1 normal forwards;
}
.text13 {
  left: 80px;
  top: 0;
  animation: text-animation13 1s ease-in-out 3.6s 1 normal forwards, text2-animation13 2s ease-in-out 5s 1 normal forwards;
}
.text13::after {
  animation: text-after-animation13 2s ease-in-out 3s 1 normal forwards;
}
.text14 {
  left: 120px;
  top: 0;
  animation: text-animation14 1s ease-in-out 3.8s 1 normal forwards, text2-animation14 2s ease-in-out 5s 1 normal forwards;
}
.text14::after {
  animation: text-after-animation14 2s ease-in-out 3s 1 normal forwards;
}
.text15 {
  left: 160px;
  top: 0;
  animation: text-animation15 1s ease-in-out 4s 1 normal forwards, text2-animation15 2s ease-in-out 5s 1 normal forwards;
}
.text15::after {
  animation: text-after-animation15 2s ease-in-out 3s 1 normal forwards;
}
.text16 {
  left: 200px;
  top: 0;
  animation: text-animation16 1s ease-in-out 4.2s 1 normal forwards, text2-animation16 2s ease-in-out 5s 1 normal forwards;
}
.text16::after {
  animation: text-after-animation16 2s ease-in-out 3s 1 normal forwards;
}
.text17 {
  left: 240px;
  top: 0;
  animation: text-animation17 1s ease-in-out 4.4s 1 normal forwards, text2-animation17 2s ease-in-out 5s 1 normal forwards;
}
.text17::after {
  animation: text-after-animation17 2s ease-in-out 3s 1 normal forwards;
}
.text18 {
  left: 280px;
  top: 0;
  animation: text-animation18 1s ease-in-out 4.6s 1 normal forwards, text2-animation18 2s ease-in-out 5s 1 normal forwards;
}
.text18::after {
  animation: text-after-animation18 2s ease-in-out 3s 1 normal forwards;
}
.text19 {
  left: 320px;
  top: 0;
  animation: text-animation19 1s ease-in-out 4.800000000000001s 1 normal forwards, text2-animation19 2s ease-in-out 5s 1 normal forwards;
}
.text19::after {
  animation: text-after-animation19 2s ease-in-out 3s 1 normal forwards;
}
.text20 {
  left: 360px;
  top: 0;
  animation: text-animation20 1s ease-in-out 5s 1 normal forwards, text2-animation20 2s ease-in-out 5s 1 normal forwards;
}
.text20::after {
  animation: text-after-animation20 2s ease-in-out 3s 1 normal forwards;
}
.text21 {
  left: 400px;
  top: 0;
  animation: text-animation21 1s ease-in-out 5.2s 1 normal forwards, text2-animation21 2s ease-in-out 5s 1 normal forwards;
}
.text21::after {
  animation: text-after-animation21 2s ease-in-out 3s 1 normal forwards;
}
.text22 {
  left: 440px;
  top: 0;
  animation: text-animation22 1s ease-in-out 5.4s 1 normal forwards, text2-animation22 2s ease-in-out 5s 1 normal forwards;
}
.text22::after {
  animation: text-after-animation22 2s ease-in-out 3s 1 normal forwards;
}
.text23 {
  left: 480px;
  top: 0;
  animation: text-animation23 1s ease-in-out 5.600000000000001s 1 normal forwards, text2-animation23 2s ease-in-out 5s 1 normal forwards;
}
.text23::after {
  animation: text-after-animation23 2s ease-in-out 3s 1 normal forwards;
}
@-moz-keyframes text-animation0 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation0 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation0 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation0 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation1 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation1 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation1 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation1 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation2 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation2 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation2 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation2 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation3 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation3 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation3 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation3 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation4 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation4 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation4 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation4 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation5 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation5 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation5 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation5 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation6 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation6 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation6 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation6 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation7 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation7 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation7 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation7 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation8 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation8 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation8 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation8 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation9 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation9 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation9 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation9 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation10 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation10 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation10 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation10 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation11 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation11 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation11 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation11 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation12 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation12 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation12 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation12 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation13 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation13 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation13 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation13 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation14 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation14 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation14 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation14 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation15 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation15 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation15 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation15 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation16 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation16 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation16 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation16 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation17 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation17 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation17 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation17 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation18 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation18 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation18 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation18 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation19 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation19 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation19 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation19 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation20 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation20 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation20 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation20 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation21 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation21 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation21 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation21 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation22 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation22 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation22 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation22 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-animation23 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation23 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-o-keyframes text-animation23 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation23 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-moz-keyframes text-after-animation0 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation0 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation0 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation0 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation1 {
  0% {
    width: 0px;
    background-color: #eb7247;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation1 {
  0% {
    width: 0px;
    background-color: #eb7247;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation1 {
  0% {
    width: 0px;
    background-color: #eb7247;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation1 {
  0% {
    width: 0px;
    background-color: #eb7247;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation2 {
  0% {
    width: 0px;
    background-color: #eb9d47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation2 {
  0% {
    width: 0px;
    background-color: #eb9d47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation2 {
  0% {
    width: 0px;
    background-color: #eb9d47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation2 {
  0% {
    width: 0px;
    background-color: #eb9d47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation3 {
  0% {
    width: 0px;
    background-color: #ebc747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation3 {
  0% {
    width: 0px;
    background-color: #ebc747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation3 {
  0% {
    width: 0px;
    background-color: #ebc747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation3 {
  0% {
    width: 0px;
    background-color: #ebc747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation4 {
  0% {
    width: 0px;
    background-color: #e4eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation4 {
  0% {
    width: 0px;
    background-color: #e4eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation4 {
  0% {
    width: 0px;
    background-color: #e4eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation4 {
  0% {
    width: 0px;
    background-color: #e4eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation5 {
  0% {
    width: 0px;
    background-color: #b9eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation5 {
  0% {
    width: 0px;
    background-color: #b9eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation5 {
  0% {
    width: 0px;
    background-color: #b9eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation5 {
  0% {
    width: 0px;
    background-color: #b9eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation6 {
  0% {
    width: 0px;
    background-color: #8eeb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation6 {
  0% {
    width: 0px;
    background-color: #8eeb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation6 {
  0% {
    width: 0px;
    background-color: #8eeb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation6 {
  0% {
    width: 0px;
    background-color: #8eeb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation7 {
  0% {
    width: 0px;
    background-color: #64eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation7 {
  0% {
    width: 0px;
    background-color: #64eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation7 {
  0% {
    width: 0px;
    background-color: #64eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation7 {
  0% {
    width: 0px;
    background-color: #64eb47;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation8 {
  0% {
    width: 0px;
    background-color: #47eb56;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation8 {
  0% {
    width: 0px;
    background-color: #47eb56;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation8 {
  0% {
    width: 0px;
    background-color: #47eb56;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation8 {
  0% {
    width: 0px;
    background-color: #47eb56;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation9 {
  0% {
    width: 0px;
    background-color: #47eb80;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation9 {
  0% {
    width: 0px;
    background-color: #47eb80;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation9 {
  0% {
    width: 0px;
    background-color: #47eb80;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation9 {
  0% {
    width: 0px;
    background-color: #47eb80;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation10 {
  0% {
    width: 0px;
    background-color: #47ebab;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation10 {
  0% {
    width: 0px;
    background-color: #47ebab;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation10 {
  0% {
    width: 0px;
    background-color: #47ebab;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation10 {
  0% {
    width: 0px;
    background-color: #47ebab;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation11 {
  0% {
    width: 0px;
    background-color: #47ebd5;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation11 {
  0% {
    width: 0px;
    background-color: #47ebd5;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation11 {
  0% {
    width: 0px;
    background-color: #47ebd5;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation11 {
  0% {
    width: 0px;
    background-color: #47ebd5;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation12 {
  0% {
    width: 0px;
    background-color: #47d5eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation12 {
  0% {
    width: 0px;
    background-color: #47d5eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation12 {
  0% {
    width: 0px;
    background-color: #47d5eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation12 {
  0% {
    width: 0px;
    background-color: #47d5eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation13 {
  0% {
    width: 0px;
    background-color: #47abeb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation13 {
  0% {
    width: 0px;
    background-color: #47abeb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation13 {
  0% {
    width: 0px;
    background-color: #47abeb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation13 {
  0% {
    width: 0px;
    background-color: #47abeb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation14 {
  0% {
    width: 0px;
    background-color: #4780eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation14 {
  0% {
    width: 0px;
    background-color: #4780eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation14 {
  0% {
    width: 0px;
    background-color: #4780eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation14 {
  0% {
    width: 0px;
    background-color: #4780eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation15 {
  0% {
    width: 0px;
    background-color: #4756eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation15 {
  0% {
    width: 0px;
    background-color: #4756eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation15 {
  0% {
    width: 0px;
    background-color: #4756eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation15 {
  0% {
    width: 0px;
    background-color: #4756eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation16 {
  0% {
    width: 0px;
    background-color: #6447eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation16 {
  0% {
    width: 0px;
    background-color: #6447eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation16 {
  0% {
    width: 0px;
    background-color: #6447eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation16 {
  0% {
    width: 0px;
    background-color: #6447eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation17 {
  0% {
    width: 0px;
    background-color: #8e47eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation17 {
  0% {
    width: 0px;
    background-color: #8e47eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation17 {
  0% {
    width: 0px;
    background-color: #8e47eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation17 {
  0% {
    width: 0px;
    background-color: #8e47eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation18 {
  0% {
    width: 0px;
    background-color: #b947eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation18 {
  0% {
    width: 0px;
    background-color: #b947eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation18 {
  0% {
    width: 0px;
    background-color: #b947eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation18 {
  0% {
    width: 0px;
    background-color: #b947eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation19 {
  0% {
    width: 0px;
    background-color: #e447eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation19 {
  0% {
    width: 0px;
    background-color: #e447eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation19 {
  0% {
    width: 0px;
    background-color: #e447eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation19 {
  0% {
    width: 0px;
    background-color: #e447eb;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation20 {
  0% {
    width: 0px;
    background-color: #eb47c7;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation20 {
  0% {
    width: 0px;
    background-color: #eb47c7;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation20 {
  0% {
    width: 0px;
    background-color: #eb47c7;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation20 {
  0% {
    width: 0px;
    background-color: #eb47c7;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation21 {
  0% {
    width: 0px;
    background-color: #eb479d;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation21 {
  0% {
    width: 0px;
    background-color: #eb479d;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation21 {
  0% {
    width: 0px;
    background-color: #eb479d;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation21 {
  0% {
    width: 0px;
    background-color: #eb479d;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation22 {
  0% {
    width: 0px;
    background-color: #eb4772;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation22 {
  0% {
    width: 0px;
    background-color: #eb4772;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation22 {
  0% {
    width: 0px;
    background-color: #eb4772;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation22 {
  0% {
    width: 0px;
    background-color: #eb4772;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text-after-animation23 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation23 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text-after-animation23 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text-after-animation23 {
  0% {
    width: 0px;
    background-color: #eb4747;
    opacity: 1;
  }
  50% {
    width: 40px;
    opacity: 1;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation0 {
  0% {
    left: -440px;
    opacity: 1;
  }
  50% {
    left: -400px;
    opacity: 0;
  }
  100% {
    left: -400px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation0 {
  0% {
    left: -440px;
    opacity: 1;
  }
  50% {
    left: -400px;
    opacity: 0;
  }
  100% {
    left: -400px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation0 {
  0% {
    left: -440px;
    opacity: 1;
  }
  50% {
    left: -400px;
    opacity: 0;
  }
  100% {
    left: -400px;
    opacity: 0;
  }
}
@keyframes text2-animation0 {
  0% {
    left: -440px;
    opacity: 1;
  }
  50% {
    left: -400px;
    opacity: 0;
  }
  100% {
    left: -400px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation1 {
  0% {
    left: -400px;
    opacity: 1;
  }
  50% {
    left: -360px;
    opacity: 0;
  }
  100% {
    left: -360px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation1 {
  0% {
    left: -400px;
    opacity: 1;
  }
  50% {
    left: -360px;
    opacity: 0;
  }
  100% {
    left: -360px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation1 {
  0% {
    left: -400px;
    opacity: 1;
  }
  50% {
    left: -360px;
    opacity: 0;
  }
  100% {
    left: -360px;
    opacity: 0;
  }
}
@keyframes text2-animation1 {
  0% {
    left: -400px;
    opacity: 1;
  }
  50% {
    left: -360px;
    opacity: 0;
  }
  100% {
    left: -360px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation2 {
  0% {
    left: -360px;
    opacity: 1;
  }
  50% {
    left: -320px;
    opacity: 0;
  }
  100% {
    left: -320px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation2 {
  0% {
    left: -360px;
    opacity: 1;
  }
  50% {
    left: -320px;
    opacity: 0;
  }
  100% {
    left: -320px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation2 {
  0% {
    left: -360px;
    opacity: 1;
  }
  50% {
    left: -320px;
    opacity: 0;
  }
  100% {
    left: -320px;
    opacity: 0;
  }
}
@keyframes text2-animation2 {
  0% {
    left: -360px;
    opacity: 1;
  }
  50% {
    left: -320px;
    opacity: 0;
  }
  100% {
    left: -320px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation3 {
  0% {
    left: -320px;
    opacity: 1;
  }
  50% {
    left: -280px;
    opacity: 0;
  }
  100% {
    left: -280px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation3 {
  0% {
    left: -320px;
    opacity: 1;
  }
  50% {
    left: -280px;
    opacity: 0;
  }
  100% {
    left: -280px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation3 {
  0% {
    left: -320px;
    opacity: 1;
  }
  50% {
    left: -280px;
    opacity: 0;
  }
  100% {
    left: -280px;
    opacity: 0;
  }
}
@keyframes text2-animation3 {
  0% {
    left: -320px;
    opacity: 1;
  }
  50% {
    left: -280px;
    opacity: 0;
  }
  100% {
    left: -280px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation4 {
  0% {
    left: -280px;
    opacity: 1;
  }
  50% {
    left: -240px;
    opacity: 0;
  }
  100% {
    left: -240px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation4 {
  0% {
    left: -280px;
    opacity: 1;
  }
  50% {
    left: -240px;
    opacity: 0;
  }
  100% {
    left: -240px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation4 {
  0% {
    left: -280px;
    opacity: 1;
  }
  50% {
    left: -240px;
    opacity: 0;
  }
  100% {
    left: -240px;
    opacity: 0;
  }
}
@keyframes text2-animation4 {
  0% {
    left: -280px;
    opacity: 1;
  }
  50% {
    left: -240px;
    opacity: 0;
  }
  100% {
    left: -240px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation5 {
  0% {
    left: -240px;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  100% {
    left: -200px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation5 {
  0% {
    left: -240px;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  100% {
    left: -200px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation5 {
  0% {
    left: -240px;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  100% {
    left: -200px;
    opacity: 0;
  }
}
@keyframes text2-animation5 {
  0% {
    left: -240px;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  100% {
    left: -200px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation6 {
  0% {
    left: -200px;
    opacity: 1;
  }
  50% {
    left: -160px;
    opacity: 0;
  }
  100% {
    left: -160px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation6 {
  0% {
    left: -200px;
    opacity: 1;
  }
  50% {
    left: -160px;
    opacity: 0;
  }
  100% {
    left: -160px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation6 {
  0% {
    left: -200px;
    opacity: 1;
  }
  50% {
    left: -160px;
    opacity: 0;
  }
  100% {
    left: -160px;
    opacity: 0;
  }
}
@keyframes text2-animation6 {
  0% {
    left: -200px;
    opacity: 1;
  }
  50% {
    left: -160px;
    opacity: 0;
  }
  100% {
    left: -160px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation7 {
  0% {
    left: -160px;
    opacity: 1;
  }
  50% {
    left: -120px;
    opacity: 0;
  }
  100% {
    left: -120px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation7 {
  0% {
    left: -160px;
    opacity: 1;
  }
  50% {
    left: -120px;
    opacity: 0;
  }
  100% {
    left: -120px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation7 {
  0% {
    left: -160px;
    opacity: 1;
  }
  50% {
    left: -120px;
    opacity: 0;
  }
  100% {
    left: -120px;
    opacity: 0;
  }
}
@keyframes text2-animation7 {
  0% {
    left: -160px;
    opacity: 1;
  }
  50% {
    left: -120px;
    opacity: 0;
  }
  100% {
    left: -120px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation8 {
  0% {
    left: -120px;
    opacity: 1;
  }
  50% {
    left: -80px;
    opacity: 0;
  }
  100% {
    left: -80px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation8 {
  0% {
    left: -120px;
    opacity: 1;
  }
  50% {
    left: -80px;
    opacity: 0;
  }
  100% {
    left: -80px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation8 {
  0% {
    left: -120px;
    opacity: 1;
  }
  50% {
    left: -80px;
    opacity: 0;
  }
  100% {
    left: -80px;
    opacity: 0;
  }
}
@keyframes text2-animation8 {
  0% {
    left: -120px;
    opacity: 1;
  }
  50% {
    left: -80px;
    opacity: 0;
  }
  100% {
    left: -80px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation9 {
  0% {
    left: -80px;
    opacity: 1;
  }
  50% {
    left: -40px;
    opacity: 0;
  }
  100% {
    left: -40px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation9 {
  0% {
    left: -80px;
    opacity: 1;
  }
  50% {
    left: -40px;
    opacity: 0;
  }
  100% {
    left: -40px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation9 {
  0% {
    left: -80px;
    opacity: 1;
  }
  50% {
    left: -40px;
    opacity: 0;
  }
  100% {
    left: -40px;
    opacity: 0;
  }
}
@keyframes text2-animation9 {
  0% {
    left: -80px;
    opacity: 1;
  }
  50% {
    left: -40px;
    opacity: 0;
  }
  100% {
    left: -40px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation10 {
  0% {
    left: -40px;
    opacity: 1;
  }
  50% {
    left: 0px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation10 {
  0% {
    left: -40px;
    opacity: 1;
  }
  50% {
    left: 0px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation10 {
  0% {
    left: -40px;
    opacity: 1;
  }
  50% {
    left: 0px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 0;
  }
}
@keyframes text2-animation10 {
  0% {
    left: -40px;
    opacity: 1;
  }
  50% {
    left: 0px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation11 {
  0% {
    left: 0px;
    opacity: 1;
  }
  50% {
    left: 40px;
    opacity: 0;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation11 {
  0% {
    left: 0px;
    opacity: 1;
  }
  50% {
    left: 40px;
    opacity: 0;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation11 {
  0% {
    left: 0px;
    opacity: 1;
  }
  50% {
    left: 40px;
    opacity: 0;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@keyframes text2-animation11 {
  0% {
    left: 0px;
    opacity: 1;
  }
  50% {
    left: 40px;
    opacity: 0;
  }
  100% {
    left: 40px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation12 {
  0% {
    left: 40px;
    opacity: 1;
  }
  50% {
    left: 80px;
    opacity: 0;
  }
  100% {
    left: 80px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation12 {
  0% {
    left: 40px;
    opacity: 1;
  }
  50% {
    left: 80px;
    opacity: 0;
  }
  100% {
    left: 80px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation12 {
  0% {
    left: 40px;
    opacity: 1;
  }
  50% {
    left: 80px;
    opacity: 0;
  }
  100% {
    left: 80px;
    opacity: 0;
  }
}
@keyframes text2-animation12 {
  0% {
    left: 40px;
    opacity: 1;
  }
  50% {
    left: 80px;
    opacity: 0;
  }
  100% {
    left: 80px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation13 {
  0% {
    left: 80px;
    opacity: 1;
  }
  50% {
    left: 120px;
    opacity: 0;
  }
  100% {
    left: 120px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation13 {
  0% {
    left: 80px;
    opacity: 1;
  }
  50% {
    left: 120px;
    opacity: 0;
  }
  100% {
    left: 120px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation13 {
  0% {
    left: 80px;
    opacity: 1;
  }
  50% {
    left: 120px;
    opacity: 0;
  }
  100% {
    left: 120px;
    opacity: 0;
  }
}
@keyframes text2-animation13 {
  0% {
    left: 80px;
    opacity: 1;
  }
  50% {
    left: 120px;
    opacity: 0;
  }
  100% {
    left: 120px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation14 {
  0% {
    left: 120px;
    opacity: 1;
  }
  50% {
    left: 160px;
    opacity: 0;
  }
  100% {
    left: 160px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation14 {
  0% {
    left: 120px;
    opacity: 1;
  }
  50% {
    left: 160px;
    opacity: 0;
  }
  100% {
    left: 160px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation14 {
  0% {
    left: 120px;
    opacity: 1;
  }
  50% {
    left: 160px;
    opacity: 0;
  }
  100% {
    left: 160px;
    opacity: 0;
  }
}
@keyframes text2-animation14 {
  0% {
    left: 120px;
    opacity: 1;
  }
  50% {
    left: 160px;
    opacity: 0;
  }
  100% {
    left: 160px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation15 {
  0% {
    left: 160px;
    opacity: 1;
  }
  50% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 200px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation15 {
  0% {
    left: 160px;
    opacity: 1;
  }
  50% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 200px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation15 {
  0% {
    left: 160px;
    opacity: 1;
  }
  50% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 200px;
    opacity: 0;
  }
}
@keyframes text2-animation15 {
  0% {
    left: 160px;
    opacity: 1;
  }
  50% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 200px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation16 {
  0% {
    left: 200px;
    opacity: 1;
  }
  50% {
    left: 240px;
    opacity: 0;
  }
  100% {
    left: 240px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation16 {
  0% {
    left: 200px;
    opacity: 1;
  }
  50% {
    left: 240px;
    opacity: 0;
  }
  100% {
    left: 240px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation16 {
  0% {
    left: 200px;
    opacity: 1;
  }
  50% {
    left: 240px;
    opacity: 0;
  }
  100% {
    left: 240px;
    opacity: 0;
  }
}
@keyframes text2-animation16 {
  0% {
    left: 200px;
    opacity: 1;
  }
  50% {
    left: 240px;
    opacity: 0;
  }
  100% {
    left: 240px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation17 {
  0% {
    left: 240px;
    opacity: 1;
  }
  50% {
    left: 280px;
    opacity: 0;
  }
  100% {
    left: 280px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation17 {
  0% {
    left: 240px;
    opacity: 1;
  }
  50% {
    left: 280px;
    opacity: 0;
  }
  100% {
    left: 280px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation17 {
  0% {
    left: 240px;
    opacity: 1;
  }
  50% {
    left: 280px;
    opacity: 0;
  }
  100% {
    left: 280px;
    opacity: 0;
  }
}
@keyframes text2-animation17 {
  0% {
    left: 240px;
    opacity: 1;
  }
  50% {
    left: 280px;
    opacity: 0;
  }
  100% {
    left: 280px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation18 {
  0% {
    left: 280px;
    opacity: 1;
  }
  50% {
    left: 320px;
    opacity: 0;
  }
  100% {
    left: 320px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation18 {
  0% {
    left: 280px;
    opacity: 1;
  }
  50% {
    left: 320px;
    opacity: 0;
  }
  100% {
    left: 320px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation18 {
  0% {
    left: 280px;
    opacity: 1;
  }
  50% {
    left: 320px;
    opacity: 0;
  }
  100% {
    left: 320px;
    opacity: 0;
  }
}
@keyframes text2-animation18 {
  0% {
    left: 280px;
    opacity: 1;
  }
  50% {
    left: 320px;
    opacity: 0;
  }
  100% {
    left: 320px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation19 {
  0% {
    left: 320px;
    opacity: 1;
  }
  50% {
    left: 360px;
    opacity: 0;
  }
  100% {
    left: 360px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation19 {
  0% {
    left: 320px;
    opacity: 1;
  }
  50% {
    left: 360px;
    opacity: 0;
  }
  100% {
    left: 360px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation19 {
  0% {
    left: 320px;
    opacity: 1;
  }
  50% {
    left: 360px;
    opacity: 0;
  }
  100% {
    left: 360px;
    opacity: 0;
  }
}
@keyframes text2-animation19 {
  0% {
    left: 320px;
    opacity: 1;
  }
  50% {
    left: 360px;
    opacity: 0;
  }
  100% {
    left: 360px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation20 {
  0% {
    left: 360px;
    opacity: 1;
  }
  50% {
    left: 400px;
    opacity: 0;
  }
  100% {
    left: 400px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation20 {
  0% {
    left: 360px;
    opacity: 1;
  }
  50% {
    left: 400px;
    opacity: 0;
  }
  100% {
    left: 400px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation20 {
  0% {
    left: 360px;
    opacity: 1;
  }
  50% {
    left: 400px;
    opacity: 0;
  }
  100% {
    left: 400px;
    opacity: 0;
  }
}
@keyframes text2-animation20 {
  0% {
    left: 360px;
    opacity: 1;
  }
  50% {
    left: 400px;
    opacity: 0;
  }
  100% {
    left: 400px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation21 {
  0% {
    left: 400px;
    opacity: 1;
  }
  50% {
    left: 440px;
    opacity: 0;
  }
  100% {
    left: 440px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation21 {
  0% {
    left: 400px;
    opacity: 1;
  }
  50% {
    left: 440px;
    opacity: 0;
  }
  100% {
    left: 440px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation21 {
  0% {
    left: 400px;
    opacity: 1;
  }
  50% {
    left: 440px;
    opacity: 0;
  }
  100% {
    left: 440px;
    opacity: 0;
  }
}
@keyframes text2-animation21 {
  0% {
    left: 400px;
    opacity: 1;
  }
  50% {
    left: 440px;
    opacity: 0;
  }
  100% {
    left: 440px;
    opacity: 0;
  }
}
@-moz-keyframes text2-animation22 {
  0% {
    left: 440px;
    opacity: 1;
    top: 0;
    transform: scale(1, 1);
  }
  50% {
    left: 480px;
    opacity: 1;
    transform: scale(1, 1);
  }
  65% {
    top: 0;
    transform: scale(1, 1);
  }
  70% {
    transform: scale(3, 3) rotate(90deg);
    top: -30px;
  }
  75% {
    left: 480px;
    top: 0;
    opacity: 1;
    transform: scale(2, 2) rotate(90deg);
  }
  85% {
    left: 480px;
  }
  100% {
    left: 1000px;
    opacity: 0;
    transform: scale(2, 2) rotate(90deg);
  }
}
@-webkit-keyframes text2-animation22 {
  0% {
    left: 440px;
    opacity: 1;
    top: 0;
    transform: scale(1, 1);
  }
  50% {
    left: 480px;
    opacity: 1;
    transform: scale(1, 1);
  }
  65% {
    top: 0;
    transform: scale(1, 1);
  }
  70% {
    transform: scale(3, 3) rotate(90deg);
    top: -30px;
  }
  75% {
    left: 480px;
    top: 0;
    opacity: 1;
    transform: scale(2, 2) rotate(90deg);
  }
  85% {
    left: 480px;
  }
  100% {
    left: 1000px;
    opacity: 0;
    transform: scale(2, 2) rotate(90deg);
  }
}
@-o-keyframes text2-animation22 {
  0% {
    left: 440px;
    opacity: 1;
    top: 0;
    transform: scale(1, 1);
  }
  50% {
    left: 480px;
    opacity: 1;
    transform: scale(1, 1);
  }
  65% {
    top: 0;
    transform: scale(1, 1);
  }
  70% {
    transform: scale(3, 3) rotate(90deg);
    top: -30px;
  }
  75% {
    left: 480px;
    top: 0;
    opacity: 1;
    transform: scale(2, 2) rotate(90deg);
  }
  85% {
    left: 480px;
  }
  100% {
    left: 1000px;
    opacity: 0;
    transform: scale(2, 2) rotate(90deg);
  }
}
@keyframes text2-animation22 {
  0% {
    left: 440px;
    opacity: 1;
    top: 0;
    transform: scale(1, 1);
  }
  50% {
    left: 480px;
    opacity: 1;
    transform: scale(1, 1);
  }
  65% {
    top: 0;
    transform: scale(1, 1);
  }
  70% {
    transform: scale(3, 3) rotate(90deg);
    top: -30px;
  }
  75% {
    left: 480px;
    top: 0;
    opacity: 1;
    transform: scale(2, 2) rotate(90deg);
  }
  85% {
    left: 480px;
  }
  100% {
    left: 1000px;
    opacity: 0;
    transform: scale(2, 2) rotate(90deg);
  }
}
@-moz-keyframes text2-animation23 {
  0% {
    left: 480px;
    opacity: 1;
  }
  50% {
    left: 520px;
    opacity: 0;
  }
  100% {
    left: 520px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation23 {
  0% {
    left: 480px;
    opacity: 1;
  }
  50% {
    left: 520px;
    opacity: 0;
  }
  100% {
    left: 520px;
    opacity: 0;
  }
}
@-o-keyframes text2-animation23 {
  0% {
    left: 480px;
    opacity: 1;
  }
  50% {
    left: 520px;
    opacity: 0;
  }
  100% {
    left: 520px;
    opacity: 0;
  }
}
@keyframes text2-animation23 {
  0% {
    left: 480px;
    opacity: 1;
  }
  50% {
    left: 520px;
    opacity: 0;
  }
  100% {
    left: 520px;
    opacity: 0;
  }
}
/*
* frame
*/
.frame {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0;
}
.frame0 {
  left: -440px;
  top: 0;
  animation: frame-animation0 1s ease-in-out 0ms 1 normal forwards;
  background-color: #eb4747;
}
.frame1 {
  left: -400px;
  top: 0;
  animation: frame-animation1 1s ease-in-out 200ms 1 normal forwards;
  background-color: #eb7247;
}
.frame2 {
  left: -360px;
  top: 0;
  animation: frame-animation2 1s ease-in-out 400ms 1 normal forwards;
  background-color: #eb9d47;
}
.frame3 {
  left: -320px;
  top: 0;
  animation: frame-animation3 1s ease-in-out 600ms 1 normal forwards;
  background-color: #ebc747;
}
.frame4 {
  left: -280px;
  top: 0;
  animation: frame-animation4 1s ease-in-out 800ms 1 normal forwards;
  background-color: #e4eb47;
}
.frame5 {
  left: -240px;
  top: 0;
  animation: frame-animation5 1s ease-in-out 1000ms 1 normal forwards;
  background-color: #b9eb47;
}
.frame6 {
  left: -200px;
  top: 0;
  animation: frame-animation6 1s ease-in-out 1200ms 1 normal forwards;
  background-color: #8eeb47;
}
.frame7 {
  left: -160px;
  top: 0;
  animation: frame-animation7 1s ease-in-out 1400ms 1 normal forwards;
  background-color: #64eb47;
}
.frame8 {
  left: -120px;
  top: 0;
  animation: frame-animation8 1s ease-in-out 1600ms 1 normal forwards;
  background-color: #47eb56;
}
.frame9 {
  left: -80px;
  top: 0;
  animation: frame-animation9 1s ease-in-out 1800ms 1 normal forwards;
  background-color: #47eb80;
}
.frame10 {
  left: -40px;
  top: 0;
  animation: frame-animation10 1s ease-in-out 2000ms 1 normal forwards;
  background-color: #47ebab;
}
.frame11 {
  left: 0px;
  top: 0;
  animation: frame-animation11 1s ease-in-out 2200ms 1 normal forwards;
  background-color: #47ebd5;
}
.frame12 {
  left: 40px;
  top: 0;
  animation: frame-animation12 1s ease-in-out 2400ms 1 normal forwards;
  background-color: #47d5eb;
}
.frame13 {
  left: 80px;
  top: 0;
  animation: frame-animation13 1s ease-in-out 2600ms 1 normal forwards;
  background-color: #47abeb;
}
.frame14 {
  left: 120px;
  top: 0;
  animation: frame-animation14 1s ease-in-out 2800ms 1 normal forwards;
  background-color: #4780eb;
}
.frame15 {
  left: 160px;
  top: 0;
  animation: frame-animation15 1s ease-in-out 3000ms 1 normal forwards;
  background-color: #4756eb;
}
.frame16 {
  left: 200px;
  top: 0;
  animation: frame-animation16 1s ease-in-out 3200ms 1 normal forwards;
  background-color: #6447eb;
}
.frame17 {
  left: 240px;
  top: 0;
  animation: frame-animation17 1s ease-in-out 3400ms 1 normal forwards;
  background-color: #8e47eb;
}
.frame18 {
  left: 280px;
  top: 0;
  animation: frame-animation18 1s ease-in-out 3600ms 1 normal forwards;
  background-color: #b947eb;
}
.frame19 {
  left: 320px;
  top: 0;
  animation: frame-animation19 1s ease-in-out 3800ms 1 normal forwards;
  background-color: #e447eb;
}
.frame20 {
  left: 360px;
  top: 0;
  animation: frame-animation20 1s ease-in-out 4000ms 1 normal forwards;
  background-color: #eb47c7;
}
.frame21 {
  left: 400px;
  top: 0;
  animation: frame-animation21 1s ease-in-out 4200ms 1 normal forwards;
  background-color: #eb479d;
}
.frame22 {
  left: 440px;
  top: 0;
  animation: frame-animation22 1s ease-in-out 4400ms 1 normal forwards;
  background-color: #eb4772;
}
.frame23 {
  left: 480px;
  top: 0;
  animation: frame-animation23 1s ease-in-out 4600ms 1 normal forwards;
  background-color: #eb4747;
}
@-moz-keyframes frame-animation0 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation0 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation0 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation0 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation1 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation1 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation1 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation1 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation2 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation2 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation2 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation2 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation3 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation3 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation3 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation3 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation4 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation4 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation4 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation4 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation5 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation5 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation5 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation5 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation6 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation6 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation6 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation6 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation7 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation7 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation7 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation7 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation8 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation8 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation8 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation8 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation9 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation9 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation9 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation9 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation10 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation10 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation10 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation10 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation11 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation11 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation11 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation11 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation12 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation12 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation12 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation12 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation13 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation13 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation13 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation13 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation14 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation14 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation14 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation14 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation15 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation15 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation15 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation15 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation16 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation16 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation16 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation16 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation17 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation17 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation17 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation17 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation18 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation18 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation18 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation18 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation19 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation19 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation19 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation19 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation20 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation20 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation20 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation20 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation21 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation21 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation21 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation21 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation22 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation22 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation22 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation22 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-moz-keyframes frame-animation23 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation23 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-o-keyframes frame-animation23 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation23 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
/*
* particle
*/
.particle {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.particle00 {
  left: -440px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation00 1s ease-in-out 1s 1 normal forwards;
}
.particle01 {
  left: -440px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation01 1s ease-in-out 1s 1 normal forwards;
}
.particle02 {
  left: -440px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation02 1s ease-in-out 1s 1 normal forwards;
}
.particle03 {
  left: -440px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation03 1s ease-in-out 1s 1 normal forwards;
}
.particle04 {
  left: -440px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation04 1s ease-in-out 1s 1 normal forwards;
}
.particle05 {
  left: -440px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation05 1s ease-in-out 1s 1 normal forwards;
}
.particle06 {
  left: -440px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation06 1s ease-in-out 1s 1 normal forwards;
}
.particle10 {
  left: -400px;
  opacity: 0;
  background-color: #eb7247;
  animation: particle-animation10 1s ease-in-out 1.2s 1 normal forwards;
}
.particle11 {
  left: -400px;
  opacity: 0;
  background-color: #eb7247;
  animation: particle-animation11 1s ease-in-out 1.2s 1 normal forwards;
}
.particle12 {
  left: -400px;
  opacity: 0;
  background-color: #eb7247;
  animation: particle-animation12 1s ease-in-out 1.2s 1 normal forwards;
}
.particle13 {
  left: -400px;
  opacity: 0;
  background-color: #eb7247;
  animation: particle-animation13 1s ease-in-out 1.2s 1 normal forwards;
}
.particle14 {
  left: -400px;
  opacity: 0;
  background-color: #eb7247;
  animation: particle-animation14 1s ease-in-out 1.2s 1 normal forwards;
}
.particle15 {
  left: -400px;
  opacity: 0;
  background-color: #eb7247;
  animation: particle-animation15 1s ease-in-out 1.2s 1 normal forwards;
}
.particle16 {
  left: -400px;
  opacity: 0;
  background-color: #eb7247;
  animation: particle-animation16 1s ease-in-out 1.2s 1 normal forwards;
}
.particle20 {
  left: -360px;
  opacity: 0;
  background-color: #eb9d47;
  animation: particle-animation20 1s ease-in-out 1.4s 1 normal forwards;
}
.particle21 {
  left: -360px;
  opacity: 0;
  background-color: #eb9d47;
  animation: particle-animation21 1s ease-in-out 1.4s 1 normal forwards;
}
.particle22 {
  left: -360px;
  opacity: 0;
  background-color: #eb9d47;
  animation: particle-animation22 1s ease-in-out 1.4s 1 normal forwards;
}
.particle23 {
  left: -360px;
  opacity: 0;
  background-color: #eb9d47;
  animation: particle-animation23 1s ease-in-out 1.4s 1 normal forwards;
}
.particle24 {
  left: -360px;
  opacity: 0;
  background-color: #eb9d47;
  animation: particle-animation24 1s ease-in-out 1.4s 1 normal forwards;
}
.particle25 {
  left: -360px;
  opacity: 0;
  background-color: #eb9d47;
  animation: particle-animation25 1s ease-in-out 1.4s 1 normal forwards;
}
.particle26 {
  left: -360px;
  opacity: 0;
  background-color: #eb9d47;
  animation: particle-animation26 1s ease-in-out 1.4s 1 normal forwards;
}
.particle30 {
  left: -320px;
  opacity: 0;
  background-color: #ebc747;
  animation: particle-animation30 1s ease-in-out 1.6s 1 normal forwards;
}
.particle31 {
  left: -320px;
  opacity: 0;
  background-color: #ebc747;
  animation: particle-animation31 1s ease-in-out 1.6s 1 normal forwards;
}
.particle32 {
  left: -320px;
  opacity: 0;
  background-color: #ebc747;
  animation: particle-animation32 1s ease-in-out 1.6s 1 normal forwards;
}
.particle33 {
  left: -320px;
  opacity: 0;
  background-color: #ebc747;
  animation: particle-animation33 1s ease-in-out 1.6s 1 normal forwards;
}
.particle34 {
  left: -320px;
  opacity: 0;
  background-color: #ebc747;
  animation: particle-animation34 1s ease-in-out 1.6s 1 normal forwards;
}
.particle35 {
  left: -320px;
  opacity: 0;
  background-color: #ebc747;
  animation: particle-animation35 1s ease-in-out 1.6s 1 normal forwards;
}
.particle36 {
  left: -320px;
  opacity: 0;
  background-color: #ebc747;
  animation: particle-animation36 1s ease-in-out 1.6s 1 normal forwards;
}
.particle40 {
  left: -280px;
  opacity: 0;
  background-color: #e4eb47;
  animation: particle-animation40 1s ease-in-out 1.8s 1 normal forwards;
}
.particle41 {
  left: -280px;
  opacity: 0;
  background-color: #e4eb47;
  animation: particle-animation41 1s ease-in-out 1.8s 1 normal forwards;
}
.particle42 {
  left: -280px;
  opacity: 0;
  background-color: #e4eb47;
  animation: particle-animation42 1s ease-in-out 1.8s 1 normal forwards;
}
.particle43 {
  left: -280px;
  opacity: 0;
  background-color: #e4eb47;
  animation: particle-animation43 1s ease-in-out 1.8s 1 normal forwards;
}
.particle44 {
  left: -280px;
  opacity: 0;
  background-color: #e4eb47;
  animation: particle-animation44 1s ease-in-out 1.8s 1 normal forwards;
}
.particle45 {
  left: -280px;
  opacity: 0;
  background-color: #e4eb47;
  animation: particle-animation45 1s ease-in-out 1.8s 1 normal forwards;
}
.particle46 {
  left: -280px;
  opacity: 0;
  background-color: #e4eb47;
  animation: particle-animation46 1s ease-in-out 1.8s 1 normal forwards;
}
.particle50 {
  left: -240px;
  opacity: 0;
  background-color: #b9eb47;
  animation: particle-animation50 1s ease-in-out 2s 1 normal forwards;
}
.particle51 {
  left: -240px;
  opacity: 0;
  background-color: #b9eb47;
  animation: particle-animation51 1s ease-in-out 2s 1 normal forwards;
}
.particle52 {
  left: -240px;
  opacity: 0;
  background-color: #b9eb47;
  animation: particle-animation52 1s ease-in-out 2s 1 normal forwards;
}
.particle53 {
  left: -240px;
  opacity: 0;
  background-color: #b9eb47;
  animation: particle-animation53 1s ease-in-out 2s 1 normal forwards;
}
.particle54 {
  left: -240px;
  opacity: 0;
  background-color: #b9eb47;
  animation: particle-animation54 1s ease-in-out 2s 1 normal forwards;
}
.particle55 {
  left: -240px;
  opacity: 0;
  background-color: #b9eb47;
  animation: particle-animation55 1s ease-in-out 2s 1 normal forwards;
}
.particle56 {
  left: -240px;
  opacity: 0;
  background-color: #b9eb47;
  animation: particle-animation56 1s ease-in-out 2s 1 normal forwards;
}
.particle60 {
  left: -200px;
  opacity: 0;
  background-color: #8eeb47;
  animation: particle-animation60 1s ease-in-out 2.2s 1 normal forwards;
}
.particle61 {
  left: -200px;
  opacity: 0;
  background-color: #8eeb47;
  animation: particle-animation61 1s ease-in-out 2.2s 1 normal forwards;
}
.particle62 {
  left: -200px;
  opacity: 0;
  background-color: #8eeb47;
  animation: particle-animation62 1s ease-in-out 2.2s 1 normal forwards;
}
.particle63 {
  left: -200px;
  opacity: 0;
  background-color: #8eeb47;
  animation: particle-animation63 1s ease-in-out 2.2s 1 normal forwards;
}
.particle64 {
  left: -200px;
  opacity: 0;
  background-color: #8eeb47;
  animation: particle-animation64 1s ease-in-out 2.2s 1 normal forwards;
}
.particle65 {
  left: -200px;
  opacity: 0;
  background-color: #8eeb47;
  animation: particle-animation65 1s ease-in-out 2.2s 1 normal forwards;
}
.particle66 {
  left: -200px;
  opacity: 0;
  background-color: #8eeb47;
  animation: particle-animation66 1s ease-in-out 2.2s 1 normal forwards;
}
.particle70 {
  left: -160px;
  opacity: 0;
  background-color: #64eb47;
  animation: particle-animation70 1s ease-in-out 2.4s 1 normal forwards;
}
.particle71 {
  left: -160px;
  opacity: 0;
  background-color: #64eb47;
  animation: particle-animation71 1s ease-in-out 2.4s 1 normal forwards;
}
.particle72 {
  left: -160px;
  opacity: 0;
  background-color: #64eb47;
  animation: particle-animation72 1s ease-in-out 2.4s 1 normal forwards;
}
.particle73 {
  left: -160px;
  opacity: 0;
  background-color: #64eb47;
  animation: particle-animation73 1s ease-in-out 2.4s 1 normal forwards;
}
.particle74 {
  left: -160px;
  opacity: 0;
  background-color: #64eb47;
  animation: particle-animation74 1s ease-in-out 2.4s 1 normal forwards;
}
.particle75 {
  left: -160px;
  opacity: 0;
  background-color: #64eb47;
  animation: particle-animation75 1s ease-in-out 2.4s 1 normal forwards;
}
.particle76 {
  left: -160px;
  opacity: 0;
  background-color: #64eb47;
  animation: particle-animation76 1s ease-in-out 2.4s 1 normal forwards;
}
.particle80 {
  left: -120px;
  opacity: 0;
  background-color: #47eb56;
  animation: particle-animation80 1s ease-in-out 2.6s 1 normal forwards;
}
.particle81 {
  left: -120px;
  opacity: 0;
  background-color: #47eb56;
  animation: particle-animation81 1s ease-in-out 2.6s 1 normal forwards;
}
.particle82 {
  left: -120px;
  opacity: 0;
  background-color: #47eb56;
  animation: particle-animation82 1s ease-in-out 2.6s 1 normal forwards;
}
.particle83 {
  left: -120px;
  opacity: 0;
  background-color: #47eb56;
  animation: particle-animation83 1s ease-in-out 2.6s 1 normal forwards;
}
.particle84 {
  left: -120px;
  opacity: 0;
  background-color: #47eb56;
  animation: particle-animation84 1s ease-in-out 2.6s 1 normal forwards;
}
.particle85 {
  left: -120px;
  opacity: 0;
  background-color: #47eb56;
  animation: particle-animation85 1s ease-in-out 2.6s 1 normal forwards;
}
.particle86 {
  left: -120px;
  opacity: 0;
  background-color: #47eb56;
  animation: particle-animation86 1s ease-in-out 2.6s 1 normal forwards;
}
.particle90 {
  left: -80px;
  opacity: 0;
  background-color: #47eb80;
  animation: particle-animation90 1s ease-in-out 2.8s 1 normal forwards;
}
.particle91 {
  left: -80px;
  opacity: 0;
  background-color: #47eb80;
  animation: particle-animation91 1s ease-in-out 2.8s 1 normal forwards;
}
.particle92 {
  left: -80px;
  opacity: 0;
  background-color: #47eb80;
  animation: particle-animation92 1s ease-in-out 2.8s 1 normal forwards;
}
.particle93 {
  left: -80px;
  opacity: 0;
  background-color: #47eb80;
  animation: particle-animation93 1s ease-in-out 2.8s 1 normal forwards;
}
.particle94 {
  left: -80px;
  opacity: 0;
  background-color: #47eb80;
  animation: particle-animation94 1s ease-in-out 2.8s 1 normal forwards;
}
.particle95 {
  left: -80px;
  opacity: 0;
  background-color: #47eb80;
  animation: particle-animation95 1s ease-in-out 2.8s 1 normal forwards;
}
.particle96 {
  left: -80px;
  opacity: 0;
  background-color: #47eb80;
  animation: particle-animation96 1s ease-in-out 2.8s 1 normal forwards;
}
.particle100 {
  left: -40px;
  opacity: 0;
  background-color: #47ebab;
  animation: particle-animation100 1s ease-in-out 3s 1 normal forwards;
}
.particle101 {
  left: -40px;
  opacity: 0;
  background-color: #47ebab;
  animation: particle-animation101 1s ease-in-out 3s 1 normal forwards;
}
.particle102 {
  left: -40px;
  opacity: 0;
  background-color: #47ebab;
  animation: particle-animation102 1s ease-in-out 3s 1 normal forwards;
}
.particle103 {
  left: -40px;
  opacity: 0;
  background-color: #47ebab;
  animation: particle-animation103 1s ease-in-out 3s 1 normal forwards;
}
.particle104 {
  left: -40px;
  opacity: 0;
  background-color: #47ebab;
  animation: particle-animation104 1s ease-in-out 3s 1 normal forwards;
}
.particle105 {
  left: -40px;
  opacity: 0;
  background-color: #47ebab;
  animation: particle-animation105 1s ease-in-out 3s 1 normal forwards;
}
.particle106 {
  left: -40px;
  opacity: 0;
  background-color: #47ebab;
  animation: particle-animation106 1s ease-in-out 3s 1 normal forwards;
}
.particle110 {
  left: 0px;
  opacity: 0;
  background-color: #47ebd5;
  animation: particle-animation110 1s ease-in-out 3.2s 1 normal forwards;
}
.particle111 {
  left: 0px;
  opacity: 0;
  background-color: #47ebd5;
  animation: particle-animation111 1s ease-in-out 3.2s 1 normal forwards;
}
.particle112 {
  left: 0px;
  opacity: 0;
  background-color: #47ebd5;
  animation: particle-animation112 1s ease-in-out 3.2s 1 normal forwards;
}
.particle113 {
  left: 0px;
  opacity: 0;
  background-color: #47ebd5;
  animation: particle-animation113 1s ease-in-out 3.2s 1 normal forwards;
}
.particle114 {
  left: 0px;
  opacity: 0;
  background-color: #47ebd5;
  animation: particle-animation114 1s ease-in-out 3.2s 1 normal forwards;
}
.particle115 {
  left: 0px;
  opacity: 0;
  background-color: #47ebd5;
  animation: particle-animation115 1s ease-in-out 3.2s 1 normal forwards;
}
.particle116 {
  left: 0px;
  opacity: 0;
  background-color: #47ebd5;
  animation: particle-animation116 1s ease-in-out 3.2s 1 normal forwards;
}
.particle120 {
  left: 40px;
  opacity: 0;
  background-color: #47d5eb;
  animation: particle-animation120 1s ease-in-out 3.4s 1 normal forwards;
}
.particle121 {
  left: 40px;
  opacity: 0;
  background-color: #47d5eb;
  animation: particle-animation121 1s ease-in-out 3.4s 1 normal forwards;
}
.particle122 {
  left: 40px;
  opacity: 0;
  background-color: #47d5eb;
  animation: particle-animation122 1s ease-in-out 3.4s 1 normal forwards;
}
.particle123 {
  left: 40px;
  opacity: 0;
  background-color: #47d5eb;
  animation: particle-animation123 1s ease-in-out 3.4s 1 normal forwards;
}
.particle124 {
  left: 40px;
  opacity: 0;
  background-color: #47d5eb;
  animation: particle-animation124 1s ease-in-out 3.4s 1 normal forwards;
}
.particle125 {
  left: 40px;
  opacity: 0;
  background-color: #47d5eb;
  animation: particle-animation125 1s ease-in-out 3.4s 1 normal forwards;
}
.particle126 {
  left: 40px;
  opacity: 0;
  background-color: #47d5eb;
  animation: particle-animation126 1s ease-in-out 3.4s 1 normal forwards;
}
.particle130 {
  left: 80px;
  opacity: 0;
  background-color: #47abeb;
  animation: particle-animation130 1s ease-in-out 3.6s 1 normal forwards;
}
.particle131 {
  left: 80px;
  opacity: 0;
  background-color: #47abeb;
  animation: particle-animation131 1s ease-in-out 3.6s 1 normal forwards;
}
.particle132 {
  left: 80px;
  opacity: 0;
  background-color: #47abeb;
  animation: particle-animation132 1s ease-in-out 3.6s 1 normal forwards;
}
.particle133 {
  left: 80px;
  opacity: 0;
  background-color: #47abeb;
  animation: particle-animation133 1s ease-in-out 3.6s 1 normal forwards;
}
.particle134 {
  left: 80px;
  opacity: 0;
  background-color: #47abeb;
  animation: particle-animation134 1s ease-in-out 3.6s 1 normal forwards;
}
.particle135 {
  left: 80px;
  opacity: 0;
  background-color: #47abeb;
  animation: particle-animation135 1s ease-in-out 3.6s 1 normal forwards;
}
.particle136 {
  left: 80px;
  opacity: 0;
  background-color: #47abeb;
  animation: particle-animation136 1s ease-in-out 3.6s 1 normal forwards;
}
.particle140 {
  left: 120px;
  opacity: 0;
  background-color: #4780eb;
  animation: particle-animation140 1s ease-in-out 3.8s 1 normal forwards;
}
.particle141 {
  left: 120px;
  opacity: 0;
  background-color: #4780eb;
  animation: particle-animation141 1s ease-in-out 3.8s 1 normal forwards;
}
.particle142 {
  left: 120px;
  opacity: 0;
  background-color: #4780eb;
  animation: particle-animation142 1s ease-in-out 3.8s 1 normal forwards;
}
.particle143 {
  left: 120px;
  opacity: 0;
  background-color: #4780eb;
  animation: particle-animation143 1s ease-in-out 3.8s 1 normal forwards;
}
.particle144 {
  left: 120px;
  opacity: 0;
  background-color: #4780eb;
  animation: particle-animation144 1s ease-in-out 3.8s 1 normal forwards;
}
.particle145 {
  left: 120px;
  opacity: 0;
  background-color: #4780eb;
  animation: particle-animation145 1s ease-in-out 3.8s 1 normal forwards;
}
.particle146 {
  left: 120px;
  opacity: 0;
  background-color: #4780eb;
  animation: particle-animation146 1s ease-in-out 3.8s 1 normal forwards;
}
.particle150 {
  left: 160px;
  opacity: 0;
  background-color: #4756eb;
  animation: particle-animation150 1s ease-in-out 4s 1 normal forwards;
}
.particle151 {
  left: 160px;
  opacity: 0;
  background-color: #4756eb;
  animation: particle-animation151 1s ease-in-out 4s 1 normal forwards;
}
.particle152 {
  left: 160px;
  opacity: 0;
  background-color: #4756eb;
  animation: particle-animation152 1s ease-in-out 4s 1 normal forwards;
}
.particle153 {
  left: 160px;
  opacity: 0;
  background-color: #4756eb;
  animation: particle-animation153 1s ease-in-out 4s 1 normal forwards;
}
.particle154 {
  left: 160px;
  opacity: 0;
  background-color: #4756eb;
  animation: particle-animation154 1s ease-in-out 4s 1 normal forwards;
}
.particle155 {
  left: 160px;
  opacity: 0;
  background-color: #4756eb;
  animation: particle-animation155 1s ease-in-out 4s 1 normal forwards;
}
.particle156 {
  left: 160px;
  opacity: 0;
  background-color: #4756eb;
  animation: particle-animation156 1s ease-in-out 4s 1 normal forwards;
}
.particle160 {
  left: 200px;
  opacity: 0;
  background-color: #6447eb;
  animation: particle-animation160 1s ease-in-out 4.2s 1 normal forwards;
}
.particle161 {
  left: 200px;
  opacity: 0;
  background-color: #6447eb;
  animation: particle-animation161 1s ease-in-out 4.2s 1 normal forwards;
}
.particle162 {
  left: 200px;
  opacity: 0;
  background-color: #6447eb;
  animation: particle-animation162 1s ease-in-out 4.2s 1 normal forwards;
}
.particle163 {
  left: 200px;
  opacity: 0;
  background-color: #6447eb;
  animation: particle-animation163 1s ease-in-out 4.2s 1 normal forwards;
}
.particle164 {
  left: 200px;
  opacity: 0;
  background-color: #6447eb;
  animation: particle-animation164 1s ease-in-out 4.2s 1 normal forwards;
}
.particle165 {
  left: 200px;
  opacity: 0;
  background-color: #6447eb;
  animation: particle-animation165 1s ease-in-out 4.2s 1 normal forwards;
}
.particle166 {
  left: 200px;
  opacity: 0;
  background-color: #6447eb;
  animation: particle-animation166 1s ease-in-out 4.2s 1 normal forwards;
}
.particle170 {
  left: 240px;
  opacity: 0;
  background-color: #8e47eb;
  animation: particle-animation170 1s ease-in-out 4.4s 1 normal forwards;
}
.particle171 {
  left: 240px;
  opacity: 0;
  background-color: #8e47eb;
  animation: particle-animation171 1s ease-in-out 4.4s 1 normal forwards;
}
.particle172 {
  left: 240px;
  opacity: 0;
  background-color: #8e47eb;
  animation: particle-animation172 1s ease-in-out 4.4s 1 normal forwards;
}
.particle173 {
  left: 240px;
  opacity: 0;
  background-color: #8e47eb;
  animation: particle-animation173 1s ease-in-out 4.4s 1 normal forwards;
}
.particle174 {
  left: 240px;
  opacity: 0;
  background-color: #8e47eb;
  animation: particle-animation174 1s ease-in-out 4.4s 1 normal forwards;
}
.particle175 {
  left: 240px;
  opacity: 0;
  background-color: #8e47eb;
  animation: particle-animation175 1s ease-in-out 4.4s 1 normal forwards;
}
.particle176 {
  left: 240px;
  opacity: 0;
  background-color: #8e47eb;
  animation: particle-animation176 1s ease-in-out 4.4s 1 normal forwards;
}
.particle180 {
  left: 280px;
  opacity: 0;
  background-color: #b947eb;
  animation: particle-animation180 1s ease-in-out 4.6s 1 normal forwards;
}
.particle181 {
  left: 280px;
  opacity: 0;
  background-color: #b947eb;
  animation: particle-animation181 1s ease-in-out 4.6s 1 normal forwards;
}
.particle182 {
  left: 280px;
  opacity: 0;
  background-color: #b947eb;
  animation: particle-animation182 1s ease-in-out 4.6s 1 normal forwards;
}
.particle183 {
  left: 280px;
  opacity: 0;
  background-color: #b947eb;
  animation: particle-animation183 1s ease-in-out 4.6s 1 normal forwards;
}
.particle184 {
  left: 280px;
  opacity: 0;
  background-color: #b947eb;
  animation: particle-animation184 1s ease-in-out 4.6s 1 normal forwards;
}
.particle185 {
  left: 280px;
  opacity: 0;
  background-color: #b947eb;
  animation: particle-animation185 1s ease-in-out 4.6s 1 normal forwards;
}
.particle186 {
  left: 280px;
  opacity: 0;
  background-color: #b947eb;
  animation: particle-animation186 1s ease-in-out 4.6s 1 normal forwards;
}
.particle190 {
  left: 320px;
  opacity: 0;
  background-color: #e447eb;
  animation: particle-animation190 1s ease-in-out 4.800000000000001s 1 normal forwards;
}
.particle191 {
  left: 320px;
  opacity: 0;
  background-color: #e447eb;
  animation: particle-animation191 1s ease-in-out 4.800000000000001s 1 normal forwards;
}
.particle192 {
  left: 320px;
  opacity: 0;
  background-color: #e447eb;
  animation: particle-animation192 1s ease-in-out 4.800000000000001s 1 normal forwards;
}
.particle193 {
  left: 320px;
  opacity: 0;
  background-color: #e447eb;
  animation: particle-animation193 1s ease-in-out 4.800000000000001s 1 normal forwards;
}
.particle194 {
  left: 320px;
  opacity: 0;
  background-color: #e447eb;
  animation: particle-animation194 1s ease-in-out 4.800000000000001s 1 normal forwards;
}
.particle195 {
  left: 320px;
  opacity: 0;
  background-color: #e447eb;
  animation: particle-animation195 1s ease-in-out 4.800000000000001s 1 normal forwards;
}
.particle196 {
  left: 320px;
  opacity: 0;
  background-color: #e447eb;
  animation: particle-animation196 1s ease-in-out 4.800000000000001s 1 normal forwards;
}
.particle200 {
  left: 360px;
  opacity: 0;
  background-color: #eb47c7;
  animation: particle-animation200 1s ease-in-out 5s 1 normal forwards;
}
.particle201 {
  left: 360px;
  opacity: 0;
  background-color: #eb47c7;
  animation: particle-animation201 1s ease-in-out 5s 1 normal forwards;
}
.particle202 {
  left: 360px;
  opacity: 0;
  background-color: #eb47c7;
  animation: particle-animation202 1s ease-in-out 5s 1 normal forwards;
}
.particle203 {
  left: 360px;
  opacity: 0;
  background-color: #eb47c7;
  animation: particle-animation203 1s ease-in-out 5s 1 normal forwards;
}
.particle204 {
  left: 360px;
  opacity: 0;
  background-color: #eb47c7;
  animation: particle-animation204 1s ease-in-out 5s 1 normal forwards;
}
.particle205 {
  left: 360px;
  opacity: 0;
  background-color: #eb47c7;
  animation: particle-animation205 1s ease-in-out 5s 1 normal forwards;
}
.particle206 {
  left: 360px;
  opacity: 0;
  background-color: #eb47c7;
  animation: particle-animation206 1s ease-in-out 5s 1 normal forwards;
}
.particle210 {
  left: 400px;
  opacity: 0;
  background-color: #eb479d;
  animation: particle-animation210 1s ease-in-out 5.2s 1 normal forwards;
}
.particle211 {
  left: 400px;
  opacity: 0;
  background-color: #eb479d;
  animation: particle-animation211 1s ease-in-out 5.2s 1 normal forwards;
}
.particle212 {
  left: 400px;
  opacity: 0;
  background-color: #eb479d;
  animation: particle-animation212 1s ease-in-out 5.2s 1 normal forwards;
}
.particle213 {
  left: 400px;
  opacity: 0;
  background-color: #eb479d;
  animation: particle-animation213 1s ease-in-out 5.2s 1 normal forwards;
}
.particle214 {
  left: 400px;
  opacity: 0;
  background-color: #eb479d;
  animation: particle-animation214 1s ease-in-out 5.2s 1 normal forwards;
}
.particle215 {
  left: 400px;
  opacity: 0;
  background-color: #eb479d;
  animation: particle-animation215 1s ease-in-out 5.2s 1 normal forwards;
}
.particle216 {
  left: 400px;
  opacity: 0;
  background-color: #eb479d;
  animation: particle-animation216 1s ease-in-out 5.2s 1 normal forwards;
}
.particle220 {
  left: 440px;
  opacity: 0;
  background-color: #eb4772;
  animation: particle-animation220 1s ease-in-out 5.4s 1 normal forwards;
}
.particle221 {
  left: 440px;
  opacity: 0;
  background-color: #eb4772;
  animation: particle-animation221 1s ease-in-out 5.4s 1 normal forwards;
}
.particle222 {
  left: 440px;
  opacity: 0;
  background-color: #eb4772;
  animation: particle-animation222 1s ease-in-out 5.4s 1 normal forwards;
}
.particle223 {
  left: 440px;
  opacity: 0;
  background-color: #eb4772;
  animation: particle-animation223 1s ease-in-out 5.4s 1 normal forwards;
}
.particle224 {
  left: 440px;
  opacity: 0;
  background-color: #eb4772;
  animation: particle-animation224 1s ease-in-out 5.4s 1 normal forwards;
}
.particle225 {
  left: 440px;
  opacity: 0;
  background-color: #eb4772;
  animation: particle-animation225 1s ease-in-out 5.4s 1 normal forwards;
}
.particle226 {
  left: 440px;
  opacity: 0;
  background-color: #eb4772;
  animation: particle-animation226 1s ease-in-out 5.4s 1 normal forwards;
}
.particle230 {
  left: 480px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation230 1s ease-in-out 5.600000000000001s 1 normal forwards;
}
.particle231 {
  left: 480px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation231 1s ease-in-out 5.600000000000001s 1 normal forwards;
}
.particle232 {
  left: 480px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation232 1s ease-in-out 5.600000000000001s 1 normal forwards;
}
.particle233 {
  left: 480px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation233 1s ease-in-out 5.600000000000001s 1 normal forwards;
}
.particle234 {
  left: 480px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation234 1s ease-in-out 5.600000000000001s 1 normal forwards;
}
.particle235 {
  left: 480px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation235 1s ease-in-out 5.600000000000001s 1 normal forwards;
}
.particle236 {
  left: 480px;
  opacity: 0;
  background-color: #eb4747;
  animation: particle-animation236 1s ease-in-out 5.600000000000001s 1 normal forwards;
}
@-moz-keyframes particle-animation00 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation00 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation00 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation00 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation01 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation01 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation01 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation01 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation02 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -490px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation02 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -490px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation02 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -490px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation02 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -490px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation03 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation03 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation03 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation03 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation04 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -490px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation04 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -490px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation04 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -490px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation04 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -490px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation05 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation05 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation05 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation05 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation06 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation06 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation06 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation06 {
  0% {
    left: -440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation10 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation10 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation10 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation10 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation11 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -350px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation11 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -350px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation11 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -350px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation11 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -350px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation12 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -450px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation12 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -450px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation12 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -450px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation12 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -450px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation13 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation13 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation13 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation13 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation14 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -450px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation14 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -450px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation14 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -450px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation14 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -450px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation15 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -350px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation15 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -350px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation15 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -350px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation15 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -350px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation16 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation16 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation16 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation16 {
  0% {
    left: -400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation20 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation20 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation20 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation20 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation21 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -310px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation21 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -310px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation21 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -310px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation21 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -310px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation22 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -410px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation22 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -410px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation22 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -410px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation22 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -410px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation23 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation23 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation23 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation23 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation24 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -410px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation24 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -410px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation24 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -410px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation24 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -410px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation25 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -310px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation25 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -310px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation25 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -310px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation25 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -310px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation26 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation26 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation26 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation26 {
  0% {
    left: -360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation30 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation30 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation30 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation30 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation31 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -270px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation31 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -270px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation31 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -270px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation31 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -270px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation32 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -370px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation32 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -370px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation32 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -370px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation32 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -370px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation33 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation33 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation33 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation33 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation34 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -370px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation34 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -370px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation34 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -370px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation34 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -370px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation35 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -270px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation35 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -270px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation35 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -270px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation35 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -270px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation36 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation36 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation36 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation36 {
  0% {
    left: -320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation40 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation40 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation40 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation40 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation41 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation41 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation41 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation41 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation42 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -330px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation42 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -330px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation42 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -330px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation42 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -330px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation43 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation43 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation43 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation43 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation44 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -330px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation44 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -330px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation44 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -330px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation44 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -330px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation45 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation45 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation45 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation45 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation46 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation46 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation46 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation46 {
  0% {
    left: -280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation50 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation50 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation50 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation50 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation51 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation51 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation51 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation51 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation52 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation52 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation52 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation52 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation53 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation53 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation53 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation53 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation54 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation54 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation54 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation54 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation55 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation55 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation55 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation55 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation56 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation56 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation56 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation56 {
  0% {
    left: -240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation60 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation60 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation60 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation60 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation61 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation61 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation61 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation61 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation62 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -250px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation62 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -250px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation62 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -250px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation62 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -250px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation63 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation63 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation63 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation63 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation64 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -250px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation64 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -250px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation64 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -250px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation64 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -250px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation65 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation65 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation65 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation65 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation66 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation66 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation66 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation66 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation70 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation70 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation70 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation70 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation71 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation71 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation71 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation71 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation72 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -210px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation72 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -210px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation72 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -210px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation72 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -210px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation73 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation73 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation73 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation73 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation74 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -210px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation74 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -210px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation74 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -210px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation74 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -210px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation75 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation75 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation75 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation75 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation76 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation76 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation76 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation76 {
  0% {
    left: -160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation80 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation80 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation80 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation80 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation81 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation81 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation81 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation81 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation82 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -170px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation82 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -170px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation82 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -170px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation82 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -170px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation83 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation83 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation83 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation83 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation84 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -170px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation84 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -170px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation84 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -170px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation84 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -170px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation85 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation85 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation85 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation85 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation86 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation86 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation86 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation86 {
  0% {
    left: -120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation90 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation90 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation90 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation90 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation91 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation91 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation91 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation91 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation92 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation92 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation92 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation92 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation93 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation93 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation93 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation93 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation94 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation94 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation94 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation94 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation95 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation95 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation95 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation95 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation96 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation96 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation96 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation96 {
  0% {
    left: -80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation100 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation100 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation100 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation100 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation101 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation101 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation101 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation101 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation102 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation102 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation102 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation102 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation103 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation103 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation103 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation103 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation104 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation104 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation104 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation104 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation105 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation105 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation105 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation105 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation106 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation106 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation106 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation106 {
  0% {
    left: -40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation110 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation110 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation110 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation110 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation111 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation111 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation111 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation111 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation112 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation112 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation112 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation112 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -50px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation113 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation113 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation113 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation113 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation114 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation114 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation114 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation114 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation115 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation115 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation115 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation115 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 50px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation116 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation116 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation116 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation116 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation120 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation120 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation120 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation120 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation121 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation121 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation121 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation121 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 90px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation122 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation122 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation122 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation122 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -10px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation123 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation123 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation123 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation123 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation124 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation124 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation124 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation124 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -10px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation125 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation125 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation125 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation125 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 90px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation126 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation126 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation126 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation126 {
  0% {
    left: 40px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation130 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation130 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation130 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation130 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation131 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation131 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation131 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation131 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 130px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation132 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation132 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation132 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation132 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 30px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation133 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation133 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation133 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation133 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation134 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation134 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation134 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation134 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 30px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation135 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation135 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation135 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation135 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 130px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation136 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation136 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation136 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation136 {
  0% {
    left: 80px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation140 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation140 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation140 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation140 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation141 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 170px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation141 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 170px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation141 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 170px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation141 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 170px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation142 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation142 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation142 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation142 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 70px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation143 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation143 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation143 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation143 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 20px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation144 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation144 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation144 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation144 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 70px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation145 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 170px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation145 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 170px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation145 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 170px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation145 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 170px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation146 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation146 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation146 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation146 {
  0% {
    left: 120px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation150 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation150 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation150 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation150 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation151 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 210px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation151 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 210px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation151 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 210px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation151 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 210px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation152 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation152 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation152 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation152 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 110px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation153 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation153 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation153 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation153 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 60px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation154 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation154 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation154 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation154 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 110px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation155 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 210px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation155 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 210px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation155 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 210px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation155 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 210px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation156 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation156 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation156 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation156 {
  0% {
    left: 160px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation160 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation160 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation160 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation160 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation161 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 250px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation161 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 250px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation161 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 250px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation161 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 250px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation162 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation162 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation162 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation162 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 150px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation163 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation163 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation163 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation163 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation164 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation164 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation164 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation164 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 150px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation165 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 250px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation165 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 250px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation165 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 250px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation165 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 250px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation166 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation166 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation166 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation166 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation170 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation170 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation170 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation170 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation171 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 290px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation171 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 290px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation171 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 290px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation171 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 290px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation172 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation172 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation172 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation172 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 190px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation173 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation173 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation173 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation173 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 140px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation174 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation174 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation174 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation174 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 190px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation175 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 290px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation175 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 290px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation175 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 290px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation175 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 290px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation176 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation176 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation176 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation176 {
  0% {
    left: 240px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation180 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation180 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation180 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation180 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation181 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 330px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation181 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 330px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation181 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 330px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation181 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 330px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation182 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation182 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation182 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation182 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 230px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation183 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation183 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation183 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation183 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 180px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation184 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation184 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation184 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation184 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 230px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation185 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 330px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation185 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 330px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation185 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 330px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation185 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 330px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation186 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation186 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation186 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation186 {
  0% {
    left: 280px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation190 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation190 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation190 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation190 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation191 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 370px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation191 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 370px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation191 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 370px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation191 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 370px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation192 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 270px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation192 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 270px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation192 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 270px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation192 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 270px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation193 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation193 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation193 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation193 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 220px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation194 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 270px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation194 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 270px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation194 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 270px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation194 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 270px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation195 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 370px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation195 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 370px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation195 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 370px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation195 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 370px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation196 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation196 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation196 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation196 {
  0% {
    left: 320px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 420px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation200 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation200 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation200 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation200 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation201 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 410px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation201 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 410px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation201 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 410px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation201 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 410px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation202 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 310px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation202 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 310px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation202 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 310px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation202 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 310px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation203 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation203 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation203 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation203 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 260px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation204 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 310px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation204 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 310px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation204 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 310px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation204 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 310px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation205 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 410px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation205 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 410px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation205 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 410px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation205 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 410px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation206 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation206 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation206 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation206 {
  0% {
    left: 360px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 460px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation210 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation210 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation210 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation210 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation211 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 450px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation211 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 450px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation211 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 450px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation211 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 450px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation212 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 350px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation212 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 350px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation212 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 350px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation212 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 350px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation213 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation213 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation213 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation213 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation214 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 350px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation214 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 350px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation214 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 350px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation214 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 350px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation215 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 450px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation215 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 450px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation215 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 450px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation215 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 450px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation216 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation216 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation216 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation216 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation220 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation220 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation220 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation220 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation221 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 490px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation221 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 490px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation221 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 490px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation221 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 490px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation222 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 390px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation222 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 390px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation222 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 390px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation222 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 390px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation223 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation223 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation223 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation223 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 340px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation224 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 390px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation224 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 390px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation224 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 390px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation224 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 390px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation225 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 490px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation225 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 490px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation225 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 490px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation225 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 490px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation226 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation226 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation226 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation226 {
  0% {
    left: 440px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 540px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation230 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 580px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation230 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 580px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation230 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 580px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation230 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 580px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation231 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 530px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation231 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 530px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation231 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 530px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation231 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 530px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation232 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 430px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation232 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 430px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation232 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 430px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation232 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 430px;
    top: 86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation233 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation233 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation233 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation233 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 380px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation234 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 430px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation234 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 430px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation234 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 430px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation234 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 430px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation235 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 530px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation235 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 530px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation235 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 530px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation235 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 530px;
    top: -86.60254040000001px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes particle-animation236 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 580px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation236 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 580px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-o-keyframes particle-animation236 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 580px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation236 {
  0% {
    left: 480px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 580px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-moz-keyframes background-animation {
  0% {
    width: 0;
  }
  50% {
    width: 12.5%;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 25%;
  }
}
@-webkit-keyframes background-animation {
  0% {
    width: 0;
  }
  50% {
    width: 12.5%;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 25%;
  }
}
@-o-keyframes background-animation {
  0% {
    width: 0;
  }
  50% {
    width: 12.5%;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 25%;
  }
}
@keyframes background-animation {
  0% {
    width: 0;
  }
  50% {
    width: 12.5%;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 25%;
  }
}
.actc {
	align-items: center;
	justify-content: center;
	height: 100vh;
}