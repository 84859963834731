.wrapper {
    max-width: 320px;
    margin: 20px auto;
}
.card {
    border: 1pt solid rgba(22, 24, 35, 0.03);
    box-shadow: 0px 0px 2px 2px rgba(22, 24, 35, 0.03);
    padding: 16px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    & .quochuy {
        width: 100px;
    }
}
.footer {
    display: flex;
    justify-content: space-between;
}
