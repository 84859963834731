.item {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    cursor: pointer;
	border-bottom: 1pt solid #f0f0f0;

    &:hover {
        background: rgba(22, 24, 35, 0.03);
    }
}
a.item:last-child {
	border-bottom: none;
}
.name {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0px;
}