.contentchat {
  background-color: #eeeffc;
  padding: 8px 16px;
  border-radius: 0px 8px 8px 8px;
  max-width: 80%;
  display: inline-block;
}
.headeritemchat {
  .created {
    font-size: 10px;
    color: #999;
    cursor: pointer;
  }
  & h4 {
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    margin-right: 8px;
    color: #333;
    margin-bottom: 0px;
  }
}
.itemchat {
  display: flex;
  margin-bottom: 8px;
  .avatar {
    margin-right: 8px;
    margin-top: 8px;
  }
}
.itemchatfromme {
  margin-bottom: 8px;
  .contentchat {
    border-radius: 8px 0px 8px 8px;
  }
  > div {
    text-align: right;
  }
}
.inputsendmess {
  width: 100%;
}
.listchatitems {
  max-height: 400px;
  overflow-y: scroll;
}
