.app {
	height: 100vh;
	display: flex;
	flex-direction: column;
  }
  
  .top {
	flex: 0;
	box-shadow:  0 1px 6px rgb(0 0 0 / 0.2);
  }
  
  .body {
	flex: 1;
	//display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 60px;
	.content {
		overflow: scroll;
		height: calc(100vh - 120px);
		margin-top: 50px;
	}
  }
  
  .bottom {
	position:fixed;
	bottom:0;
	width: 100%;
	background-color: #fff;
	flex: 0;
	box-shadow:  0 1px 6px rgb(0 0 0 / 0.2);
  }