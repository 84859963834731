.icon {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 4px;
}
.dropdown-Todo {
  color: #ffa125;
}
.dropdown-Todo .icon {
  background-color: #ffa125;
}
.dropdown-Doing {
  color: #d4380d;
}
.dropdown-Doing .icon {
  background-color: #d4380d;
}
.dropdown-Done {
  color: #389e0d;
}
.dropdown-Done .icon {
  background-color: #389e0d;
}
.dropdown-Review {
  color: #096dd9;
}
.dropdown-Review .icon {
  background-color: #096dd9;
}
.dropdown-Hold {
  color: #999;
}
.dropdown-Hold .icon {
  background-color: #999;
}

.listusers {
  .item {
    display: flex;
    align-content: center;
  }
  .avatar {
	width: 18px;
	height: 18px;
  }
  .avatar img {
    height: 18px;
    width: 18px;
  }
}
