.body {
    margin: 0;
    width: 100vw;
    height: 100vh;
    background-color:rgba(159, 159, 159, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}
.rounder {
	position: relative;
}
.tip {
    top: 46px;
    text-align-last: center;
    font-weight: bold;
    font-size: 18px;
    position: relative;
}

.randomTip {
    top: 30px;
    text-align-last: center;
    font-style: italic;
    font-size: 18px;
    position: relative;
}

.progressbar {
	height: 12px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(159, 159, 159, 0.5);
    z-index: 999;
}
.loading {
    transition: all 500ms ease;
    height: 5px;
    width: calc(100% - 9px);
    border-radius: 8px;
    background: #474747;
    position: absolute;
    margin: 3px;
    display: inline-block;
    animation: load 12s ease infinite;
}
.loadText {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    font-size: 18px;
}

@keyframes load {
    0% {
        width: 2%;
    }
    10% {
        width: 10%;
    }
}
