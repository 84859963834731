.listusers {
    .itemuser {
        margin-bottom: 12px;
        display: flex;
        font-size: 14px;
        align-items: center;
        padding: 8px;
        .useravatar {
            margin-right: 8px;
        }
        .username {
            font-weight: 600;
            color: #333;
        }
        .useremail {
            color: #ccc;
        }
    }
    .itemuser:hover {
        background-color: #fafafa;
    }
}
.boxuserdetail {
    display: flex;
    align-items: center;
    justify-content: center;
    .avataruser {
		height: 80px;width: 80px;
        margin-right: 16px;
		img {height: 80px;width: 80px;}
    }
	.username {margin-bottom: 0px;font-weight: 600;}
	.useremail {color:#ccc}
}
