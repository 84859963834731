.iscomplete {
    text-decoration: line-through;
    color: #999;
}
.no-label {
    margin: -16px;
    & label {
        display: none !important;
    }
    & .ant-form-item {
        margin-bottom: 0px !important;
    }
}
.editinline {
    & button {
        display: none;
        width: 22px;
        height: 22px;
    }
}
.editinline:hover {
    & button {
        display: block;
    }
}
.taskrounder {
    border: 1pt solid #eee;
    margin-bottom: 8px;
    padding: 12px;
    box-shadow: 0px 0px 6px #eee;
    border-radius: 8px;
	.tasktitle {
		font-size: 14px;
		color: #333;
	}
	.taskduedate {
		font-size: 10px;
		color: brown;
	}
	.footer {
		display: flex;
		justify-content: space-between;
	}
}

