.header-page-breadcrumb {
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
}
.listprojects {
    & .item {
        border-bottom: 1pt solid rgba(22, 24, 35, 0.03);
        display: flex;
        align-items: center;
        padding: 5px;
    }
    & .item:hover {
        cursor: pointer;
        background-color: rgba(22, 24, 35, 0.03);
    }
    & .img {
        margin-right: 5px;
        height: 20px;
    }
}
.card {
    border: 1pt solid rgba(22, 24, 35, 0.03);
    box-shadow: 0px 0px 2px 2px rgba(22, 24, 35, 0.03);
    padding: 16px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    & .quochuy {
        width: 100px;
    }
}
.kanban_card {
    border-bottom: 1pt solid #999;
    border-right: 1pt solid #ccc;
    background-color: #fff;
    padding: 5px 8px;
    margin-top: 8px;
	.avatar {
        display: flex !important;
        margin-top: 8px;
        justify-content: space-between;
        text-align: right;
        .avatar-item {
            cursor: pointer;
        }
        .btnarea {
            display: none;
        }
    }
    .title {
        font-size: 14px;
        button {
            padding: 0px;
            white-space: normal;
            text-align: left;
        }
    }
    .time {
        font-size: 11px;
        color: #c00;
    }
    .avatar {
        text-align: right;
    }
}
.kanban_card:hover {
    background-color: #f5f5f5;
	.btnarea {
		display: block;
	}
}
.header_card {
	border-bottom: 1pt solid #fff;
	padding-bottom: 8px;
	.title {
		font-size: 20px;
		margin-bottom: 16px;
	}
}
