.list-notes {
  .item-note {
    border: 1pt solid #f0f0f0;
    padding: 4px 8px;
    border-radius: 8px;
    margin-bottom: 8px;
	position: relative;
  }
  .item-note:last-child {
    margin-bottom: 0px;
  }
  .datetime {
    font-size: 12px;
    color: #C00;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
  }
  .btn-delete {
	position: absolute;
	top:10px;right:10px
  }
  .desc {
	color:rgba(0, 0, 0, 0.45)
  }
}
